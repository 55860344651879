<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->

          <b-button
            class="flex-shrink-0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'admin-settings-holidays-calender' }"
          >
            Calender View
          </b-button>

          <template v-if="$permissionAbility(HOLIDAYS_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        mode="remote"
        @on-per-page-change="onPerPageChange"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <template v-if="props.column.field === 'format_title'">
            <b>{{ props.row.title }}</b>
          </template>

          <!-- Column: employee type -->
          <span v-if="props.column.field === 'employee_type'">
            <span class="text-nowrap">{{
              props.row?.employeeType?.data?.name
            }}</span>
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template v-if="$permissionAbility(HOLIDAYS_EDIT, permissions)">
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(HOLIDAYS_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <!-- modal-holidays-form -->
    <b-modal
      id="modal-holidays-form"
      centered
      :title="modelType == 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="holidaysValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- title-->
          <b-form-group label="Title" label-for="title">
            <ValidationProvider
              name="title"
              v-slot="{ errors }"
              vid="title"
              rules="required"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select start date-->
          <template v-if="modelType != 'editModel'">
            <b-form-group label="Start Date" label-for="start-date">
              <ValidationProvider
                name="start_date"
                v-slot="{ errors }"
                vid="start_date"
                rules="required"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="start_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
                  @context="onContextStartDate"
                  :date-disabled-fn="dateDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <!-- select end date-->
          <template v-if="modelType != 'editModel'">
            <b-form-group label="End Date" label-for="end-date">
              <ValidationProvider
                name="end_date"
                v-slot="{ errors }"
                vid="end_date"
                rules="required"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="end_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
                  @context="onContextEndDate"
                  :date-disabled-fn="dateDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <!-- remarks -->
          <b-form-group label="Description" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="description"
              vid="description"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false : null"
                placeholder="Description"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isHolidaysFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  HOLIDAYS_CREATE,
  HOLIDAYS_EDIT,
  HOLIDAYS_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "HolidaysView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      HOLIDAYS_CREATE,
      HOLIDAYS_EDIT,
      HOLIDAYS_DELETE,
      modelType: "",
      holidayId: "",
      title: "",
      description: "",
      start_date: "",
      end_date: "",
      isHolidaysFormSubmitLoading: false,
      weekends: [],
      holidays: [],

      // table
      pageLength: 10,
      columns: [
        {
          label: "Date",
          field: "date",
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Description",
          field: "description",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
      // table
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.getAllWeekends();
      this.getHolidaysByYear({
        year: new Date().getFullYear(),
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    dateDisabled(ymd, date) {
      return this.weekends.includes(ymd) || this.holidays.includes(ymd);
    },
    onContextStartDate(ctx) {
      this.start_date = ctx.selectedYMD;
    },
    onContextEndDate(ctx) {
      this.end_date = ctx.selectedYMD;
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(new Date(value)).format("ll");
      }
    },
    showModal() {
      this.$bvModal.show("modal-holidays-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-holidays-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.holidayId = "";
      this.title = "";
      this.description = "";
      this.start_date = "";
      this.end_date = "";
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.holidayId = value?.id;
      this.title = value?.title;
      this.description = value?.description;
      //   this.start_date = value?.date;
      //   this.end_date = value?.date;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/holidays/${id}`);

            this.loadItems();
            this.getHolidaysByYear({
              year: new Date().getFullYear(),
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getHolidaysByYear(params) {
      try {
        const getHolidaysByYear = await this.$api.get(
          "api/holidays/get-by-year",
          {
            params: {
              year: params.year,
            },
          }
        );

        this.holidays = (getHolidaysByYear?.data?.data || []).map((item) => {
          return item?.date;
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllWeekends(params) {
      try {
        const getAllWeekends = await this.$api.get("api/weekends/all");

        this.weekends = (getAllWeekends?.data?.data || []).map((item) => {
          return item?.date;
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getHolidays(params) {
      return await this.$api.get("api/holidays", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    async loadItems() {
      try {
        const [holidays] = await Promise.all([
          this.getHolidays({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = holidays?.data?.data;
        console.log(data);
        const meta = holidays?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.holidaysValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isHolidaysFormSubmitLoading = true;
              await this.$api.put(`/api/holidays/${this.holidayId}`, {
                title: this.title,
                description: this.description,
              });
              this.isHolidaysFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isHolidaysFormSubmitLoading = true;
              await this.$api.post(
                "/api/holidays",

                {
                  title: this.title,
                  description: this.description,
                  start_date: this.start_date,
                  end_date: this.end_date,
                }
              );
              this.isHolidaysFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.getHolidaysByYear({
                year: new Date().getFullYear(),
              });

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isHolidaysFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.holidaysValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
